@charset 'UTF-8';
/* Slider */

/* Icons */
@font-face
{
    /* font-family: 'slick'; */
    font-weight: normal;
    font-style: normal;

    /* src: url('./fonts/slick.eot');
    src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg'); */
}
/* Arrows */



.projectsSlider .slick-prev,
.projectsSlider .slick-next
{
    position: absolute;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
}


.projectsSlider .slick-prev:hover,
.projectsSlider .slick-prev:focus,
.projectsSlider .slick-next:hover,
.projectsSlider .slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.projectsSlider .slick-prev:hover:before,
.projectsSlider .slick-prev:focus:before,
.projectsSlider .slick-next:hover:before,
.projectsSlider .slick-next:focus:before
{
    opacity: 1;
}
.projectsSlider .slick-prev.slick-disabled:before,
.projectsSlider .slick-next.slick-disabled:before
{
    opacity: .25;
}

.projectsSlider .slick-prev:before,
.projectsSlider .slick-next:before
{
    /* font-family: 'slick'; */
    font-size: 20px;
    line-height: 1;
    
    opacity: .75;
    color: rgba(22, 117, 161, 0.426);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



.projectsSlider .slick-prev
{
    bottom: calc(100% + 10px);
}
[dir='rtl'] .projectsSlider .slick-prev
{
    right: -35px;
    left: auto;
}
.projectsSlider .slick-prev:before
{
    content: '▲';
}
[dir='rtl'] .projectsSlider .slick-prev:before
{
    content: '▼';
}

.projectsSlider .slick-next
{
    top: calc(100% + 40px);
}
[dir='rtl'] .projectsSlider .slick-next
{
    right: auto;
    left: -35px;
}
.projectsSlider .slick-next:before
{
    content: '▼';
}
[dir='rtl'] .projectsSlider .slick-next:before
{
    content: '▲';
}



