html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
  /* border: 1px solid rgba(100, 100, 100, 0.727);
  background-color: rgba(121, 120, 119, 0.3); */
}

body {
  font-family: "Inter", sans-serif;
}

/* Loading */
.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(251, 251, 251);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingScreen img {
  max-width: 500px;

}

/* ModeSwitch Part */

.modeSwitch {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mode {
  padding-top: 1.5rem;
  display: flex;
  gap: 1rem;
  max-width: 90%;
  width: 80%;
  align-items: center;
  justify-content: right;
}
.modeText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #777777;
}

#lang {
  color: #1675a1;
  cursor: pointer;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 24px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.toggle-switch-checkbox {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 3px;
  bottom: 0;
  background-color: #1675a1;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: #ffe86e;
  transition: 0.4s;
  border-radius: 50%;
}
.slider:after {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: #ffe86e;
  transition: 0.6s;
  border-radius: 50%;
  transform: translateX(0px) translateY(-2px);
}

.toggle-switch-checkbox:checked + .slider {
  background-color: #3a3a3a;
}

.toggle-switch-checkbox:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-switch-checkbox:checked + .slider:after {
  transform: translateX(22px) translateY(-2px);
  background-color: #3a3a3a;
}

/* ModeSwitch Part*/

/* Header Part */
.HContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 2rem;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #c4e2e4;
  color: #1675a1;
  padding: 2rem;
  border-radius: 50%;
  transform: matrix(0.87, 0.49, -0.51, 0.86, 0, 0);
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
}


.nav {
  display: flex;
  gap: 5rem;
}

.nav h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #6b7280;
  text-decoration: none;
  padding: 0.75rem 2rem;
  cursor: pointer;
}
.bgColor {
  border-radius: 6px;
  background-color: #1675a1;
}
.bgColor {
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-decoration: none;
  padding: 0.75rem 2rem;
  cursor: pointer;
}

.nav a:hover {
  border: 1px solid #1675a1;
  border-radius: 6px;
  background-color: #c4e2e4;
}

/* Header Part*/

/*  Bio Part*/
.heroContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 2rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 0.75rem;
}

.hero-image {
  width: 26rem;
  height: auto;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  border-radius: 18px;
}

.hero-left {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.name {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #1675a1;
}
.lineAndName {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.line {
  margin-bottom: 0.6rem;
}
.heading {
  font-style: normal;
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 4.5rem;
  color: #1f2937;
}
.intro {
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #6b7280;
  width: 37.25rem;
}
.hire-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  gap: 0.625rem;
  width: 7.9rem;
  height: 3.25rem;
  border: 1px solid #1675a1;
  background: #1675a1;
  border-radius: 6px;
  cursor: pointer;
}
.hire-button .whiteText {
  color: #ffffff;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-decoration: none;
}
.hire-button:hover {
  cursor: pointer;
}
.github-button:hover {
  background-color: #c4e2e4;
  cursor: pointer;
}
.linkedin-button:hover {
  background-color: #c4e2e4;
  cursor: pointer;
}
.github-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  gap: 0.625rem;
  width: 7.9rem;
  height: 3.25rem;
  background: #ffffff;
  border: 1px solid #1675a1;
  border-radius: 6px;
}
.linkedin-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  gap: 0.625rem;
  width: 7.9rem;
  height: 3.25rem;
  background: #ffffff;
  border: 1px solid #1675a1;
  border-radius: 6px;
}
.buttons a {
  font-size: 1.125rem;
  color: #6b7280;
  text-decoration: none;
}
/* Bio Part*/

/* Skills Part */

.skillsContainer {
  text-align: center;
  margin: 8rem 8rem 0 8rem;
  border-bottom: 2px solid #c4e2e4;
}
.skills {
  gap: 0.3rem;
  display: flex;
}

.skill-list {
  display: flex;
  justify-content: center;
}

.heading-list {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  color: #1675a1;
  margin: 7rem 0 4rem 1.5rem;
}

.list {
  display: flex;
  justify-content: space-evenly;
}

.skill-list h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 28px;
  color: #1675a1;
  margin-bottom: 2rem;
  text-align: center;
  margin-top: 0.7rem;
}
.skill-list p {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #6b7280;
  margin-bottom: 1rem;
}

.skillImg:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.2);
  transform: scale(1.1);
}

.js h3 {
  padding-top: 1rem;
}

/* Skills Part */

/* Profile Part */

.profile {
  padding: 8rem 8rem 7rem 9rem;
  color: #46474a;
}

.profile-content {
  display: flex;
  align-items: center;
  padding: 0 1rem 0 1rem;
}

.component-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-image {
  text-align: center;
}

.pro-column {
  display: flex;
  flex-direction: row;
}

.profile-title {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  color: #1675a1;
  margin-bottom: 3rem;
}

.info-title {
  padding-bottom: 1rem;
  font-weight: 550;
  font-size: 30px;
  color: #1675a1;
}

.basic-key h5 {
  font-weight: 570;
  padding-bottom: 1.5rem;
  color: #0a0a14;
}

.basic-values p {
  padding-bottom: 1.5rem;
}

.about-me {
  line-height: 1.5rem;
  font-size: 18px;
}

.basic-info,
.about-box {
  background-color: rgba(141, 187, 213, 0.232);
  padding: 1rem;
}

#info-keys {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  width: 100%;
}

#info-value {
  justify-content: flex-start;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  width: 100%;
}

/* Profile Part */

/* Projects Part */
.projectsContainer {
  padding: 2rem 8rem 7rem 9rem;
  width: 100%;
}

.project-heading {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  color: #1675a1;
  margin-bottom: 5rem;
  margin-top: 7rem;
}

.projectCol {
  border-top: 2px solid #c4e2e4;
}

.card {
  background-color: rgba(141, 187, 213, 0.232);
  display: flex !important; 
  width: 62% !important;
  margin: 15px 0;
  border-radius: 12px;
  gap: 2rem;
  margin-left: auto;
  margin-right: auto;
  
}

.project-text {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.programs {
  display: flex;
  gap: 10px;
}

.programs p {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 18px 7px;
  background-color: #1675a1;
  border-radius: 23px;
  color: #ffffff;
}

.links {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.description-project {
  margin-bottom: 2rem;
  font-size: 14px;
}

.links a {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #1675a1;
}
.projectsPhotos {
  height: 16.25rem;
  width: 23.75rem;
}

.content-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 45px;
  color: #1675a1;
}

/* Projects Part */

/* Footer Part */
.footer_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(141, 187, 213, 0.232);
  height: 25.56rem;
  margin-top: 5rem;
}
.footer {
  display: flex;
  align-items: center;
  width: 80%;
  margin-top: 2rem;
  text-align: center;
}

.footerText {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footerLinks {
  display: flex;
  flex-direction: column;
  
}

.links-footer {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 1.25rem;
  justify-content: center;
  align-items: center;
}

.lets {
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 125%;
  color: #1675a1;
  margin-bottom: 2rem;
 

}
.lets-text{
  font-weight: 300;
  font-size: 25px;
  line-height: 125%;
  color: rgb(62, 101, 105);
margin-bottom: 1.5rem;
}

.mail {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #4049d0;
  margin-bottom: 1rem;
}
.cvLink {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #c4670b;
}
.githubFooter {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #00ab6b;
  text-decoration: none;
}
.linkedinFooter {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #0077b5;
  text-decoration: none;
}

/* Footer Part */

/* Dark Mode*/

body.dark-mode {
  background-color: #252128;
  color: #ffffff;
}

.dark-mode .component-container {
  color: white;
}

.dark-mode .pro-column{
  color:white;
}

.logo.dark-mode {
  background-color: #1675a1;
  color: #c4e2e4;
}

.footer_Container.dark-mode {
  background-color: #141414;
  color: #ffffff;
}

.lineAndName.dark-mode {
  color: #a3daec;
}
.heading.dark-mode {
  color: #a3daec;
}
.intro.dark-mode {
  color: #ffffff;
}

.links-footer p.dark-mode {
  color: #ffffff;
}
.heading-list.dark-mode {
  color: #ffffff;
}
.mail.dark-mode {
  color: #a3daec;
}
.modeText.dark-mode {
  color: #b6b0b0;
}

.dark-mode .cvLink {
  color: rgb(207, 105, 10);
}
/* Dark Mode*/

/* Responsive*/

@media only screen and (max-width: 768px) {
  body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .heroContainer,
  .skillsContent,
  .skills,
  .projects,
  .pro,
  .links-footer {
    display: flex;
    flex-direction: column;
  }

  .hero-image {
    width: 16rem;
    height: auto;
    margin-top: 2rem;
  }
  .nav {
    gap: 1.5rem;
  }

  .list {
    width: 100%;
  }
  .footer_Container {
    width: 100%;
  }
}

/* Responsive*/
